<template>
    <div class="pay-footer">
        <div :class="[status === 'orderCancel' ? 'price-c' : 'price']" v-if="status !== 'orderRefund'">
                <div>总计:</div>
                <div class="amount">￥{{ finalPayPrice }}</div>
        </div>
        <div v-else>
            <span></span>
        </div>
        <div v-if="status === 'orderPaying'" class="order-paying">
            <div class="button" @click="cancelOrder">取消订单</div>
            <div class="button-pay" @click="payNow">立即支付</div>
        </div>
        <div v-else-if="status === 'orderPicking' || status === 'orderPaid'" class="order-paying">
            <div class="button-c" @click="expedited">催包裹</div>
            <div class="button-c" @click="cancelOrder">取消订单</div>
        </div>
        <div v-else-if="status === 'orderReceived'" class="order-paying">
            <div class="button-c" @click="queryExpress">查看物流</div>
            <div class="button-c" @click="rebuy">再次购买</div>
            <div class="button-pay-c" @click="receivedGoods">确认订单</div>
        </div>
        <div v-else-if="status === 'orderCancel'" class="order-paying">
            <div class="button" @click="deleteOrder">删除订单</div>
        </div>
        <div v-else-if="status === 'orderPaidCancel'" class="order-paying">
            <div class="button-c" @click="queryRefund">查看退款</div>
            <div class="button-c" @click="deleteOrder">删除订单</div>
            <div class="button-c" @click="rebuy">再次购买</div>
        </div>
        <div v-else-if="status === 'orderRefund'" class="order-paying">
            <div class="button" @click="queryRefund">查看退款</div>
            <div class="button-c" @click="deleteOrder">删除订单</div>
            <div class="button" @click="rebuy">再次购买</div>
        </div>
        <div v-else-if="status === 'orderComment'" class="order-paying">
            <div class="button" @click="queryExpress" v-show="!virtualOrder">查看物流</div>
            <div class="button" @click="rebuy">再次购买</div>
        </div>
        <div v-else-if="status === 'orderApplyRefund'" class="order-paying">
            <div class="button-c" @click="cancelRefund">取消退款</div>
        </div>
        <div :class="[overlay ? 'button-pay-lg-overlay':'button-pay-lg']" @click="payNow" v-else-if="status === 'default'">立即支付</div>
        <!-- 全面屏适配 -->
        <div class="safe-area" style="background-color: #f9f9f9;"></div>
    </div>
</template>

<script>
export default {
  name: 'Pay',
  activated(){

  },
  components: {
  },
  data(){
      return {

      }
  },
  props:{
    finalPayPrice:{
        type: [Number, String],
        default: 0
    },
    status:{
        type: String,
        default: ''
    },
    virtualOrder:{
        type: Boolean,
        default: false
    },
    overlay:{
        type: Boolean,
        default: true
    }
  },
  methods:{
    payNow(){
        this.$emit('payNow')
    },
    expedited(){
        this.$emit('expedited')
    },
    cancelOrder(){
        this.$emit('cancelOrder')
    },
    queryExpress(){
        this.$emit('queryExpress')
    },
    receivedGoods(){
        this.$emit('received')
    },
    queryRefund(){
        this.$emit('queryRefund')
    },
    cancelRefund(){
        this.$emit('cancelRefund')
    },
    deleteOrder(){
        this.$emit('deleteOrder')
    },
    rebuy(){
        this.$emit('rebuy')
    }
  },
}
</script>

<style scoped lang="scss">
.pay-footer {
    height: 55px;
    position: fixed;
    z-index: 1;
    bottom: constant(safe-area-inset-bottom);
    bottom: env(safe-area-inset-bottom);
    display: flex;
    padding: 0 10px;
    width: 100%;
    box-sizing: border-box;
    max-width: 625px;
    margin: 0 auto;
    background: #f9f9f9;
    align-items: center;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-top: 1px rgba(0,0,0,0.05) solid;
    justify-content: space-between;
    .price {
        display: flex;
        align-items: center;
        font-size: 16px;
        padding-left: 10px;
        .amount {
            color: #f0061d;
        }
    }
    .price-c {
        display: flex;
        align-items: center;
        font-size: 16px;
        .amount {
            color: #f0061d;
        }
    }
    .order-paying {
        display: flex;
    }
}
.button {
    border: 1px rgba(0,0,0,0.2) solid;
    border-radius: 25px;
    padding: 0 10px;
    font-size: 15px;
    margin: 5px 5px;
    height: 30px;
    line-height: 30px;
}
.button-c {
    border: 1px rgba(0,0,0,0.2) solid;
    border-radius: 25px;
    padding: 0 8px;
    font-size: 15px;
    margin: 5px 2px;
    height: 30px;
    line-height: 30px;
}
.button-pay-c {
    border: 1px rgba(0,0,0,0.01) solid;
    border-radius: 25px;
    padding: 0 8px;
    font-size: 15px;
    height: 30px;
    line-height: 30px;
    margin: 5px 2px;
    background: #fc5f10;
    color: #fff;
}
.button-pay-lg {
    width: 160px;
    height: 38px;
    display: flex;
    line-height: 30px;
    border-radius: 30px;
    align-items: center;
    font-size: 14px;
    color: #fff;
    background: #29323c;
    border: 1px solid #29323c;
    // background: #fc5f10;
    // border: 1px solid #fc5f10;
    justify-content: center;
}

.button-pay-lg-overlay {
    width: 160px;
    height: 38px;
    display: flex;
    line-height: 30px;
    border-radius: 30px;
    align-items: center;
    font-size: 14px;
    color: #fff;
    background: rgba(41, 50, 60, 0.3);
    border: 1px solid #29323c;
    // background: rgba(240,6,29,0.6);
    justify-content: center;
    border: 1px solid rgba(240,6,29,0);
}

.button-pay {
    border: 1px rgba(0,0,0,0.01) solid;
    border-radius: 25px;
    padding: 0 15px;
    font-size: 15px;
    height: 30px;
    line-height: 30px;
    margin: 5px 10px;
    background: #fc5f10;
    color: #fff;
}
</style>
