<template>
    <div class="pay-box">
        <nav-index :title="'订单支付'" :urlParams="orderInfo.gid"/>
        <div v-if="address.hasAddr" class="address-default">
            <div class="add-text">
                <div class="row">
                    <div class="name">{{ address.nickname }}</div>
                    <div class="phone">{{ address.phone }}</div>
                </div>
                <div class="addr">{{ address.area + ' ' + address.desc }}</div>
            </div>
            <div class="img" @click="goAddressPage">
                <img src="../../assets/images/enter.png" alt="" width="20" height="20" v-show="address.area.length > 0">
            </div>
        </div>
        <div class="address" v-else>
            <span>新建收货地址，让快递到家~</span>
            <div class="add-icon" @click="goAddressPage">
                <span>添加</span>
                <img src="../../assets/images/enter.png" alt="" width="20" height="20">
            </div>
        </div>
        <div class="goods-card">
            <div class="title">
                <img src="../../assets/images/store.png" alt="" width="20" height="20">
                <span>{{ lists.length > 0 ? lists[0].shop : '' }}</span>
            </div>
            <div class="goods-desc" v-for="orderInfo in lists" :key="orderInfo.id">
                <div class="img">
                    <img :src="orderInfo.imgUrl" alt="" width="80" height="80">
                </div>
                <div class="card-right">
                    <div class="desc-text">{{ orderInfo.title }}</div>
                    <div class="type-text">{{ orderInfo.type }}</div>
                    <div class="price">
                        <div class="number"><span>￥</span>{{ orderInfo.originalCost }}</div>
                        <van-stepper v-model="orderInfo.amount" @change="handleChange" input-width="40px" button-size="30px"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="price-card">
            <div class="goods-price">
                <div class="title">商品金额</div>
                <div class="price">
                    <span>￥</span>
                    {{ totalPrice }}
                </div>
            </div>
            <div class="coupon-row">
                <span>活动</span>
                <div class="coupon" @click="selectCoupon">
                    <span>{{ couponText }}</span>
                    <img src="../../assets/images/enter.png" alt=""  width="20" height="20">
                </div>
            </div>
            <div class="express-charge">
                <div>运费</div>
                <div class="amount"><span>￥</span>{{ expressCharge }}</div>
            </div>
            <div class="total-price">
                <div class="total">
                    <span>总计:</span>
                    <span>￥</span>
                    <span>{{ finalPayPrice }}</span>
                </div>
            </div>
        </div>
        <div class="remainder-card">
            <div class="pay">
                <div>支付方式</div>
                <div class="payment">
                    <!-- <img src="../../assets/logo.png" alt="" width="18" height="18"> -->
                    <div class="text">待支付</div>
                </div>
            </div>
            <div class="remainder">
                <div>订单备注</div>
                <div class="text">和商家协商一致后留言</div>
            </div>
        </div>
        <pay-footer @payNow="payNow" :finalPayPrice = "finalPayPrice" :overlay="overlay" :status="'default'"/>
        <van-popup v-model="showPopup" position="bottom" round :style="'height: 50%;max-width:625px;'" @close="handleClose" closeable>
          <div class="pay-details">
            <div class="title" v-if="!isPayCancel">请选择支付方式</div>
            <div class="title" v-else>请在<van-count-down :time="time" @finish="finish"/>分钟内支付</div>
            <div class="wechat-pay" @click="wechatPayClick">
                <div class="row">
                    <img src="../../assets/images/wechat-pay.png" alt="" width="35" height="35">
                    <span>微信</span>
                </div>
                <van-checkbox v-model="wechatChecked" disabled></van-checkbox>
            </div>
            <div class="alipay" @click="alipayClick" v-show="!isWeiXin">
                <div class="row">
                    <img src="../../assets/images/alipay.png" alt="" width="35" height="35">
                    <span>支付宝</span>
                </div>
                <van-checkbox v-model="alipayChecked" disabled></van-checkbox>
            </div>
            <div class="button-row">
                <div class="button-buy" @click="submitOrder" >
                    <van-loading color="white" size="24px" v-show="isLoading"/>
                    <span v-show="!isLoading">{{ alipayChecked ? '支付宝' : '微信' }}付款</span>
                </div>
            </div>
          </div>
        </van-popup>
        <confirm ref="confirm" @goOrderPage="goOrderPage"/>
    </div>
</template>

<script>
import PayFooter from '../../components/footer/Pay.vue'
import NavIndex from '../../components/nav/index.vue'
import { querydefaultAddressById, queryCouponByCode, createOrder, queryOrderById, submitWechatPay, wxSdk, updateOrderByOid } from '@/api/api'
import wx from "weixin-js-sdk";
import {Toast} from 'vant'
import Confirm from '../../components/dialog/Confirm.vue'
import moment from 'moment'
const seo = require('../../../config/index')

export default {
  name: 'Pay',
  components: {
    NavIndex,
    PayFooter,
    Confirm
  },
  created(){
    this.getData()
  },
  mounted(){
    this.isWeiXin = this.isWeiXinUA()
    this.overlay = this.address.aid && String(this.address.aid).length > 0 ? false : true
  },
  destroyed(){
  },
  data:()=>{
    return {
        lists: null,
        totalPrice: 0,
        discount: 0,
        expressCharge: 0,
        finalPayPrice: 0,
        orderInfo:{
            gid:'', // gid是所属商品的id，oid是订单的id
            title:'',
            type:'',
            originalCost: 0,
            createAt:'',
            amount:1,
            imgUrl: '',
        },
        address:{
            aid:'',
            nickname:'',
            phone:'',
            area:'',
            desc:'',
            hasAddr: true
        },
        time: 0,
        couponText: '使用优惠券',
        isWeiXin: false,
        isLoading: false,
        isPayCancel: false,
        showPopup: false,
        alipayChecked: false,
        wechatChecked: true,
        overlay: false,
        userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo'))[0] : {}
    }
  },
  methods:{
    getData(){
        let listsTemp = sessionStorage.getItem('lists')
        if(listsTemp){
            this.lists = JSON.parse(listsTemp)
            this.orderInfo.gid = String(this.lists[0].gid)
            this.lists.forEach(list => {
                list.totalPrice = list.amount * 100 * list.originalCost / 100;
                list.totalPrice = Math.round(list.totalPrice * 100) / 100; // 四舍五入保留两位小数
            });
            this.totalPrice = this.lists.reduce((sum, list) => sum + list.totalPrice, 0);
            this.totalPrice = Math.round(this.totalPrice * 100) / 100; // 四舍五入保留两位小数
            this.finalPayPrice = this.totalPrice + this.expressCharge - this.discount
        }
        if(this.$route.query.selectedAddr){
            let selectedAddr = this.$route.query.selectedAddr
            this.address.aid = selectedAddr.id
            this.address.nickname = selectedAddr.name
            this.address.phone = selectedAddr.phone
            this.address.area = selectedAddr.area
            this.address.desc = selectedAddr.desc
            this.address.hasAddr = true
        } else {
            querydefaultAddressById({uid: this.userInfo.uid}).then((res)=>{
                this.address = {}
                if(Object.keys(res.data.data).length !== 0){
                    this.address.aid = res.data.data.aid
                    this.address.nickname = res.data.data.nickname
                    this.address.phone = res.data.data.phone
                    this.address.area = res.data.data.area
                    this.address.desc = res.data.data.desc
                    this.address.hasAddr = true
                } else {
                    this.address.hasAddr = false
                }
                this.overlay = this.address.aid && String(this.address.aid).length > 0 ? false : true
            })
        }
        // 获取优惠券信息
        this.getCouponInfo()
    },
    payNow(){
        // 提交订单至少需要一条地址
        if(Object.keys(this.address).length > 1){
            queryOrderById({ oid: this.$route.query.oid }).then((res)=>{
                if(Object.keys(res.data.data).length === 0){
                    // 生成订单
                    let params = {}
                    params.oid = this.$route.query.oid
                    params.nickname = this.address.nickname
                    params.phone_num = this.address.phone
                    params.address = this.address.area + ' ' + this.address.desc
                    params.uid = this.userInfo.uid
                    params.shop = seo.config.shopName
                    params.lists = []
                    this.lists.forEach(item=>{
                        let temp = {}
                        temp.title = item.title
                        temp.imgUrl = item.imgUrl
                        temp.type = item.type
                        temp.price = item.originalCost
                        temp.amount = item.amount
                        params.lists.push(temp)
                    })
                    params.total_price = this.totalPrice
                    params.discount = this.discount
                    params.parcel_fee = this.expressCharge
                    params.finally_price = this.finalPayPrice
                    params.payment = '1'  // 付款方式，1-wechat、2-alipay 默认微信支付
                    params.pay_time = ''  // 订单只是提交，暂未支付
                    params.order_status = 'orderPaying' // 待付款状态
                    // 创建订单
                    createOrder(params)
                    this.showPopup = true
                } else {
                    this.$refs.confirm.show('pay')
                }
            })
        } else {
            Toast('请添加地址');
        }
    },
    goMiniProgramPay(params) {
        let payDataStr = JSON.stringify(params);// 参数传递给小程序
        const url = `../pay/index?payDataStr=${payDataStr}`;
        wx.miniProgram.redirectTo({url: url})
    },
    getCouponInfo(){
        if(this.$route.query.couponCode){
            queryCouponByCode({ coupon_code: this.$route.query.couponCode, uid: this.userInfo.uid }).then(res=>{
                this.orderInfo.couponDiscount = Number(res.data.data[0].discount_amount)
                this.orderInfo.couponDiscountType = Number(res.data.data[0].type)
                if(this.orderInfo.couponDiscountType === 1){
                    this.couponText = '已优惠'+ Number(res.data.data[0].discount_amount) + '元'
                    this.discount = Number(res.data.data[0].discount_amount)
                    if(this.finalPayPrice * 100 > this.discount * 100){
                        this.finalPayPrice = ((this.totalPrice * 100 + this.expressCharge * 100 - this.discount * 100) / 100).toFixed(2)
                    } else {
                        Toast('该优惠券未满足满减条件')
                    }
                } else if(this.orderInfo.couponDiscountType === 2){
                    this.couponText = '已使用'+ Number(res.data.data[0].discount_amount) + '折券'
                    this.discount = Number(res.data.data[0].discount_amount) / 10
                    this.finalPayPrice = (Math.ceil(this.totalPrice * this.discount * 10) / 10 + this.expressCharge).toFixed(2)
                }
            })
        }
    },
    // 使用优惠券
    selectCoupon(){
        sessionStorage.setItem('fromPath', 'pay')
        this.$router.push({ path:'/coupon', query:{ oid: this.$route.query.oid, gid: this.$route.query.gid } })
    },
    isMiniProgram(){
        var ua = window.navigator.userAgent.toLowerCase();
        if(/miniProgram/i.test(ua)){
            return true
        } else {
            return false
        }
    },
    isWeiXinUA() {
        let ua = window.navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == 'micromessenger') {
            return true;
        } else {
            return false;
        }
    },
    wechatPayClick(){
        this.alipayChecked = false
        this.wechatChecked = true
    },
    alipayClick(){
        if(!this.isWeiXin){
            this.alipayChecked = true
            this.wechatChecked = false
        }
    },
    handleChange(){
        this.lists.forEach(list => {
            // * 100后, /100, 规避js处理浮点数无法处理
            list.totalPrice = list.amount * 100 * list.originalCost / 100
        });
        this.totalPrice = this.lists.reduce((sum, list) => sum + list.totalPrice, 0);
        this.finalPayPrice = this.totalPrice + this.expressCharge - this.discount
    },
    submitOrder(){
        let params = {}
        params.oid = this.$route.query.oid
        params.price = this.finalPayPrice
        params.title = this.lists[0].title
        params.openid = localStorage.getItem('openid') ? localStorage.getItem('openid') : ''
        if(this.finalPayPrice > 0){
            this.isLoading = true
            if(this.isWeiXin){
                if(this.isMiniProgram()){
                    this.goMiniProgramPay(params)
                } else {
                    submitWechatPay(params).then(res=>{
                        this.wxSdk(res.data)
                    })
                }
            } else {
                Toast('请前往公众号支付');
                this.isLoading = false
            }
        }
    },
    wxSdk(payRes){
        let url = seo.config.homeUrl + this.$route.fullPath
          let my = this
          wxSdk({ url:url }).then(res=>{
            wx.config({
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: seo.config.wxConfig.appId, // 必填，公众号的唯一标识
              timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
              nonceStr: res.data.data.noncestr, // 必填，生成签名的随机串
              signature: res.data.data.signature,// 必填，签名
              jsApiList: ["checkJsApi", "chooseWXPay", "updateAppMessageShareData", "updateTimelineShareData"] // 必填，需要使用的JS接口列表
            });
            wx.ready(function() {
				/* 微信支付 */
                wx.chooseWXPay({
                    appId: payRes.appId,
                    timestamp: payRes.timeStamp, // 支付签名时间戳，注意微信 jssdk 中的所有使用 timestamp 字段均为小写。但最新版的支付后台生成签名使用的 timeStamp 字段名需大写其中的 S 字符
                    nonceStr: payRes.nonceStr, // 支付签名随机串，不长于 32 位
                    package: payRes.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
                    signType: payRes.signType, // 微信支付V3的传入 RSA ,微信支付V2的传入格式与V2统一下单的签名格式保持一致
                    paySign: payRes.paySign, // 支付签名
                    // 支付成功
                    success: function (res) {
                        console.log('支付成功', res)
                        if(res.errMsg === 'chooseWXPay:ok'){
                            let payment = '1' // 1-微信支付，2-支付宝支付
                            let payTime = moment(payRes.timeStamp).format("YYYY-MM-DD HH:mm:ss");
                            updateOrderByOid({ oid: my.$route.query.oid, order_status: 'orderPaid', payment: payment, pay_time:payTime }).then(()=>{
                                my.$router.push({ path: '/pay-result', query:{ gid: my.$route.query.gid } })
                                my.isLoading = false
                            })
                        }
                    },
                    // 支付取消
                    cancel: function(res){
                        if(res.errMsg === 'chooseWXPay:cancel'){
                            Toast('支付已取消');
                            // 设置15分钟倒计时time
                            my.time = 15 * 60 * 1000
                            my.isPayCancel = true
                            my.isLoading = false
                        }
                    },
                    // 支付失败
                    fail: function(res){
                        console.log(res)
                        Toast('支付失败');
                        my.isLoading = false
                    }
                });
            })
        })
    },
    goAddressPage(){
        if(Object.keys(this.address).length === 1){
            if(this.$route.query.gid){
                this.$router.push({ path:'/address', query:{ aid: '', oid: this.$route.query.oid, gid: this.$route.query.gid } })
            } else {
                this.$router.push({ path:'/address', query:{ aid: '', oid: this.$route.query.oid } })
            }
        } else {
            if(this.$route.query.gid){
                this.$router.push({ path:'/address', query:{ aid: this.address.aid, oid: this.$route.query.oid, gid: this.$route.query.gid } })
            } else {
                this.$router.push({ path:'/address', query:{ aid: this.address.aid, oid: this.$route.query.oid } })
            }
        }
    },
    goOrderPage(){
        this.$router.push('/order')
    },
    handleClose(){
        queryOrderById({ oid: this.$route.query.oid }).then((res)=>{
            if(Object.keys(res.data.data).length > 0){
                this.$router.push('/order')
            }
        })
    },
    finish(){
        this.showPopup = false
    }
  },
}
</script>

<style scoped lang="scss">
.pay-box {
  min-height: calc(100vh - 50px);
  padding-top: 50px;
  padding-bottom: calc(55px + constant(safe-area-inset-bottom)); 
  padding-bottom: calc(55px + env(safe-area-inset-bottom));
  .address-default {
    width: calc(100% - 45px);
    background: #fff;
    margin: 10px 5px 0;
    padding: 15px 15px 15px 20px;
    border-radius: 10px;
    font-size: 18px;
    min-height: 76px;
    position: relative;
    text-align: left;
    display: flex;
    align-items: center;
    .img {
        position: absolute;
        right: 10px;
        top: 45px;
    }
    .add-text {
        margin-right: 20px;
        .name {
            padding-right: 5px;
            font-size: 18px;
            font-weight: 600;
        }
        .phone {
            color: #969699;
            font-size: 17px;
        }
    }
    .row {
        display: flex;
        align-items: center;
        margin: 0 0 5px 0;
    }
    .addr {
        font-size: 16px;
        word-break: break-all;
    }
  }
  .address {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 106px;
    width: calc(100% - 20px);
    background: #fff;
    margin: 10px 5px 0;
    padding: 0 0px 0 10px;
    border-radius: 10px;
    font-size: 18px;
    span {
        padding: 0;
        text-align: left;
    }
    .add-icon {
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 70px;
        span {
            padding: 0;
        }
    }
  }
  .goods-card {
    background: #fff;
    margin: 10px 5px;
    padding-bottom: 5px;
    border-radius: 10px;
    .title {
        display: flex;
        align-items: center;
        padding: 15px 15px;
        font-size: 15px;
    }
    .goods-desc {
        display: flex;
        position : relative;
        padding: 0 20px 15px 20px;
        .img {
        }
        img {
            border-radius: 2px;
        }
        .card-right {
            flex: 1;
            padding: 0 0px 0 15px;
            text-align: left;
            word-break: break-all;
            .desc-text {
                color: #222;
                font-weight: 600;
                display: -webkit-box;
                -webkit-box-orient: vertical;    
                -webkit-line-clamp: 1;    
                overflow: hidden;
                padding-right: 15px;
            }
            .type-text {
                margin: 8px 0 6px;
                font-size: 14px;
            }
            .price {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .number {
                    font-weight: 600;
                }
            }
        }
    }
}
    .price-card {
        background: #fff;
        margin: 10px 5px;
        border-radius: 10px;
        padding: 10px 20px;
        font-size: 16px;
        .goods-price {
            display: flex;
            justify-content: space-between;
            margin: 5px 0;
            .price {
                font-size: 18px;
                font-weight: 600;
            }
        }
        .goods-account {
            display: flex;
            justify-content: space-between;
            margin: 5px 0;
            .content {
                font-size: 14px;
            }
        }
        .coupon-row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 0px 10px 0;
            margin: 0 -5px 20px 0;
            border-radius: 5px;
            .coupon {
                display: flex;
                align-items: center;
                font-size: 16px;
                color: #969699;
            }
            img {
                margin-top: 2px;
            }
        }
        .express-charge {
            display: flex;
            justify-content: space-between;
            margin: 5px 0 10px;
            .amount {
                font-size: 18px;
                font-weight: 600;
            }
        }
        .total-price {
            display: flex;
            justify-content: flex-end;
            border-top: 1px rgba(0,0,0,0.03) solid;
            padding: 10px 0 5px;
            font-size: 14px;
        }
    }
    .remainder-card {
        background: #fff;
        margin: 10px 5px;
        border-radius: 10px;
        padding: 10px 20px;
        font-size: 16px;
        .pay {
            display: flex;
            justify-content: space-between;
            margin: 10px 0;
            .payment {
                display: flex;
                align-items: center;
                .text {
                    padding-left: 2px;
                    font-size: 16px;
                    line-height: 18px;
                }
            }
        }
        .remainder {
            display: flex;
            justify-content: space-between;
            margin: 10px 0;
            .text {
                color: rgba(0,0,0,0.3);
            }
        }
    }
    .pay-details {
        padding: 15px 20px 30px;
        background: #fff;
        .title {
            padding: 0 0 30px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .alipay {
            display: flex;
            justify-content: space-between;
            font-size: 18px;
            .row {
                display: flex;
                align-items: center;
                img {
                    padding-right: 10px;
                }
            }
        }
        .wechat-pay {
            display: flex;
            justify-content: space-between;
            font-size: 18px;
            .row {
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                img {
                    padding-right: 10px;
                }
            }
        }
        .button-row {
            position: fixed;
            bottom: 10px;
            right: 0;
            z-index: 2;
            display: flex;
            justify-content: center;
            padding: 10px;
            padding-bottom: calc(-10px + constant(safe-area-inset-bottom));
            padding-bottom: calc(-10px + env(safe-area-inset-bottom));
            background: #fff;
            left: 0;
            box-sizing: border-box;
            max-width: 625px;
            margin: 0 auto;
            .button-buy {
                width: 80%;
                height: 38px;
                display: flex;
                line-height: 30px;
                border-radius: 30px;
                align-items: center;
                font-size: 16px;
                color: #fff;
                background: #fc5f10;
                justify-content: center;
                border: 1px solid #fc5f10;
            }
        }
    }
}
::v-deep .van-checkbox__icon--checked .van-icon {
    color: #fff;
    background-color: rgba(0,0,0,0.8);
    border-color: rgba(0,0,0,0.8);
}
::v-deep .van-popup--bottom {
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    margin: 0 auto;
}
::v-deep .van-count-down {
    color: #f0061d;
    font-size: 20px;
    line-height: 20px;
    padding: 0 10px;
}
</style>